
import { Component, Vue } from 'nuxt-property-decorator'
import DocListWidget from '@simple-payments/shared-packages/components/Doc/WidgetList/DocWidgetList.vue'
import SearchForm from '~/components/SearchFormLanding.vue'
import Banner from '~/components/Banner.vue'
import ImportantInfo from '~/components/info-block/ImportantInfo.vue'
import WhereInfo from '~/components/info-block/WhereInfo.vue'
import PaymentInfo from '~/components/info-block/PaymentInfo.vue'
import FeaturesInfo from '~/components/info-block/FeaturesInfo.vue'
import GuaranteeInfo from '~/components/info-block/GuaranteeInfo.vue'
import MobileInfo from '~/components/info-block/MobileInfo.vue'
import OfficialSource from '~/components/OfficialSource.vue'
import CheckCard from '~/components/CheckCard.vue'
import LicencePlateInfo from '~/components/info-block/LicencePlateInfo.vue'
import LicensePlatePageFaq from '~/components/info-block/LicensePlatePageFaq.vue'
import FnsIndexWidgets from '~/components/index/FnsIndexWidgets.vue'
import ArticlesThree from '@simple-payments/shared-packages/components/Articles/ArticlesThree.vue'

@Component({
  components: {
    FnsIndexWidgets,
    LicensePlatePageFaq,
    LicencePlateInfo,
    CheckCard,
    OfficialSource,
    MobileInfo,
    GuaranteeInfo,
    FeaturesInfo,
    PaymentInfo,
    Banner,
    SearchForm,
    ImportantInfo,
    WhereInfo,
    DocListWidget,
    ArticlesThree
  }
})
export default class extends Vue {
  head () {
    const title = this.$route.path === '/gos-nomer' ? 'Проверить штрафы ГИБДД по гос номеру автомобиля онлайн' : 'Проверка и оплата штрафов ГИБДД онлайн по номеру, водительскому удостоверению, СТС'
    return {
      title,
      link: [
        { rel: 'preload', as: 'image', href: require('~/assets/img/popup/ic_outline-star-outline.svg') }
      ]
    }
  }

  beforeMount () {
    window.addEventListener('load', () => {
      this.$analytics.sendEvent('main')
    }, { once: true })
  }

  mounted () {
    if (this.$route.query.email) {
      this.$axios.patch(process.env.API_ENDPOINT + 'user', { email: this.$route.query.email })
    }
  }
}
