
import SearchFormAbstract from '@simple-payments/shared-packages/classes/search-form/SearchFormAbstract'
import DocUin from '@simple-payments/shared-packages/classes/doc-value/DocUin'
import { FieldsId } from '@simple-payments/shared-packages/types/enum/FieldsId'
import { Component, Prop } from 'nuxt-property-decorator'
import { TheMask } from 'vue-the-mask'
import InfoBtn from './InfoBtn.vue'
import EmailForm from './EmailForm.vue'
import { ErrorValidation } from '~/types/ErrorValidation'
import SearchFormOfertaText from '~/components/search-form/SearchFormOfertaText.vue'

@Component({
  components: {
    SearchFormOfertaText,
    InfoBtn,
    TheMask,
    EmailForm
  }
})
export default class extends SearchFormAbstract {
  @Prop() dataErrorFormValidations!: ErrorValidation
  formData = new DocUin()
  fieldId = FieldsId.UIN
  isClientErrorValid: string = ''
  isFormSubmitted = false

  getType () : string {
    return 'gibdd'
  }

  isEmailVisible () {
    if (this.formData.document_value.length >= 20 && !this.$store.getters['user/getUser'].email?.length && !this.isFormSubmitted) {
      return true
    }
    return false
  }

  afterSubmitBehavior (): Promise<any> {
    this.$nuxt.$eventBus.$emit('form-with-email')
    setTimeout(() => {
      this.isFormSubmitted = true
    }, 1500)
    return this.$router.push({
      path: '/search/' + this.getType(),
      // @ts-ignore
      query: {
        document_type: this.formData.docType(),
        lead_source_id: window.$nuxt.$route.query.lead_source_id,
        ...this.formData
      }
    })
  }

  errorClientValid () {
    this.isClientErrorValid = ''
    this.$v.$touch()

    if (!this.dataErrorFormValidations.isError) {
      // @ts-ignore
      if (this.$v.formData.document_value.$error) {
        this.isClientErrorValid = ' <span class="error-msg__item">00000000000000000000</span>'
      }
    }
  }
}
